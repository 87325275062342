<template>
	<div class="page">
		<Tables ref="xTable" :tableName="tableName" :tableData="tableData" :totalPage="totalPage" :isLoading="isLoading"
			:searchFormData="searchFormData" :showRefresh="true" :showSearch="true" @getList="getList" :showPrint="true" :showOutput="true"
			@clearSearch="clearSearch">
			<el-form-item slot="search-item">
				<el-input v-model="searchFormData.card_name" size="small" clearable placeholder="电卡名称"></el-input>
			</el-form-item>
			<el-form-item slot="search-item">
				<el-input v-model="searchFormData.order_no" size="small" clearable placeholder="按订单号查询"></el-input>
			</el-form-item>
			<el-form-item slot="search-item">
				<el-input v-model="searchFormData.phone" size="small" clearable placeholder="按手机号查询"></el-input>
			</el-form-item>
			<el-form-item slot="search-item">
				<el-input v-model="searchFormData.spread_name" size="small" clearable placeholder="推广员"></el-input>
			</el-form-item>
			<el-form-item slot="search-item">
				<el-date-picker size="small" style="width: 240px;" unlink-panels v-model="dates" type="daterange" clearable range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
				</el-date-picker>
			</el-form-item>
			<!--表格内容-->
			<vxe-table-column slot="table-item" field="order_no" title="订单号" align="center" min-width="100px" />
			<vxe-table-column slot="table-item" field="fans_id" title="用户信息" align="center" min-width="50px">
			</vxe-table-column>
			<vxe-table-column slot="table-item" field="phone" title="电话" align="center" min-width="100px" />
			<vxe-table-column slot="table-item" field="card_name" title="电卡名称" align="center" min-width="100px">
			</vxe-table-column>
			<vxe-table-column slot="table-item" field="order_price" title="面额" align="center" min-width="80px" />
			<vxe-table-column slot="table-item" field="pay_price" title="售价金额" align="center" min-width="80px" />
			<vxe-table-column slot="table-item" field="pay_status" title="支付状态" align="center" min-width="80px">
				<template v-slot="{ row }">
					<div>{{types.pay_status[row.pay_status]||'未知状态'}}</div>
					<div v-if="row.pay_status==40">{{row.refund_price}}元</div>
				</template>
			</vxe-table-column>
			<vxe-table-column slot="table-item" field="pay_time" title="付款时间" align="center" min-width="100px" />
			<vxe-table-column slot="table-item" field="order_ly" title="支付方式" align="center" min-width="100px">
				<template v-slot="{ row }">
					<span v-if="row.order_ly==10">微信小程序</span>
					<span v-if="row.order_ly==20">支付宝小程序</span>
					<span v-if="row.order_ly==50">微信APP</span>
					<span v-if="row.order_ly==60">支付宝APP</span>
				</template>
			</vxe-table-column>
			<!--vxe-table-column slot="table-item" field="ex_id" title="推广员ID" align="center" min-width="50px">
			</vxe-table-column-->
			<vxe-table-column slot="table-item" field="spread_name" title="推广员" align="center" min-width="50px">
			</vxe-table-column>
			<!--      <vxe-table-column-->
			<!--        slot="table-item"-->
			<!--        title="操作"-->
			<!--        align="center"-->
			<!--        width="180"-->
			<!--      >-->
			<!--        <template v-slot="{ row }">-->
			<!--          <el-button-->
			<!--            size="small"-->
			<!--            plain-->
			<!--            @click="refundOne(row)"-->
			<!--            v-if="row.pay_status==20&&$hasAccess('user.recharge/refund')"-->
			<!--          >退款</el-button>-->
			<!--        </template>-->
			<!--      </vxe-table-column>-->
		</Tables>
		<!-- 退款 -->
		<el-dialog title="充值退款" :visible.sync="refundDialogShow" :close-on-click-modal="false"
			:close-on-press-escape="false" width="400px">
			<el-form :model="refundDialogFormData" :rules="refundRules" size="mini" ref="refundForm" label-width="120px"
				class="form">
				<el-form-item label="订单号：">{{refundDialogFormOriData.order_no}}</el-form-item>
				<el-form-item label="实际付款金额：">{{refundDialogFormOriData.pay_price}}元</el-form-item>
				<el-form-item label="退款金额：" prop="refund_price">
					<el-input-number v-model="refundDialogFormData.refund_price" size="small" style="width: 80%;"
						controls-position="right" :min="0.01" :max="refundDialogFormOriData.pay_price" :step="1"
						:precision="2"></el-input-number>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" size="small" plain icon="el-icon-check" @click="saveRefund()"
					v-if="refundDialogFormOriData.pay_status==20&&$hasAccess('user.recharge/refund')">确 认 退 款
				</el-button>
				<el-button size="small" plain icon="el-icon-close" @click="closeDialog">取 消
				</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import Tables from '@/components/tables'
	export default {
		name: 'market-card-record',
		components: {
			Tables
		},
		data() {
			return {
				// 表格信息
				tableName: '购买记录',
				isLoading: false,
				tableData: [],
				totalPage: 0,
				dates: [],
				searchFormData: {
					u_type: 10
				},
				types: {
					pay_status: {
						10: '未支付',
						20: '已支付',
						30: '已取消',
						40: '已退款'
					}
				},
				// 用户搜索
				sLoading: false,
				sFans: [],
				// 设备搜索
				s2Loading: false,
				sDevice: [],
				// 详情
				detailDialogShow: false,
				detailDialogFormData: {},
				detailDialogUserBalance: 0,
				// 退款
				refundDialogShow: false,
				refundDialogFormData: {},
				refundDialogFormOriData: {},
				refundRules: {
					refund_price: [{
						required: true,
						message: '请填写退款金额',
						trigger: 'change'
					}]
				}
			}
		},
		methods: {
			// 表格列表
			async getList(currentPage, pageSize) {
				const params = {
					token: this.$store.state.user.token,
					page: currentPage,
					size: pageSize
				}
				this.isLoading = true
				const searchData = this.searchFormData
				// 小区限制
				const group_id = this.$store.state.user.userInfo.group_id
				if (!searchData.group_id && group_id) {
					searchData.group_id = group_id
				}
				if (this.dates[0]){
					this.searchFormData.start_time = parseInt(this.dates[0].getTime() / 1000);
					this.searchFormData.end_time = parseInt(this.dates[1].getTime() / 1000);
				}
				const res = await this.$api.Market.CardRecord(params, searchData)
				this.tableData = res.data
				this.totalPage = res.total
				this.isLoading = false
			},
			// 重置搜索
			clearSearch() {
				this.searchFormData = {
					u_type: 10
				}
				this.dates = [];
			},
			// 关闭弹窗
			closeDialog() {
				this.detailDialogShow = false
				this.refundDialogShow = false
			},
			// 退款
			refundOne(row) {
				this.refundDialogFormData = {
					order_id: row.order_id,
					refund_price: row.pay_price
				}
				this.refundDialogFormOriData = {
					pay_price: Number(row.pay_price),
					pay_status: row.pay_status,
					order_no: row.order_no
				}
				this.refundDialogShow = true
			},
			// 退款保存
			saveRefund() {
				this.$refs.refundForm.validate(async valid => {
					if (valid) {
						const params = {
							token: this.$store.state.user.token,
							form: JSON.stringify(this.refundDialogFormData)
						}
						await this.$api.Fans.RefundRecharge(params)
						this.$notify({
							title: '成功',
							message: '退款成功',
							type: 'success'
						})
						this.closeDialog()
						this.$refs.xTable.refreshTable()
					}
				})
			}
		}
	}
</script>

<style lang="scss" scoped>
	.row-title {
		font-size: 14px;
		line-height: 20px;
		padding: 4px 0;
		margin-bottom: 18px;

		&:before {
			content: " ";
			width: 5px;
			height: 20px;
			background: #008e4d;
			display: block;
			float: left;
			margin: 0 10px 0 0;
		}
	}
</style>
